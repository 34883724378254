import React from "react";
import { Row, Col } from "antd";
import "./join.less";
import arrow from "../../img/solution/arrow.png";
import arrow2 from "../../img/solution/arrow2.png";

export default class Join extends React.Component {
  constructor(props) {
    super(props);
  }
  handleClick(info) {
    if (info === "bottomjava") {
      document.getElementById("bottomjava").className = "hiden";
      document.getElementById("leftjava").className = "show";
      document.getElementById("java-describe").className = "job-describe hiden";
    } else if (info === "leftjava") {
      document.getElementById("bottomjava").className = "show";
      document.getElementById("leftjava").className = "hiden";
      document.getElementById("java-describe").className = "job-describe show";
    } else if (info === "leftfront") {
      document.getElementById("bottomfront").className = "show";
      document.getElementById("leftfront").className = "hiden";
      document.getElementById("front-describe").className = "job-describe show";
    } else {
      document.getElementById("leftfront").className = "show";
      document.getElementById("bottomfront").className = "hiden";
      document.getElementById("front-describe").className =
        "job-describe hiden";
    }
  }

  render() {
    return (
      <div style={{ width: "100%" }}>
        <Row className="join-box">
          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 6 }}
            lg={{ span: 6 }}
            xl={{ span: 16, offset: 4 }}
            className="job-top-content"
          >
            <p className="title-top">寻找与众不同的你</p>
            <p className="title-english">Welcome to join us</p>
            <p
              className="title-top"
              style={{ fontSize: "24px", marginTop: "35px" }}
            >
              简历投递：limengchang@hotmail.com
            </p>
          </Col>
        </Row>
        <Row style={{ marginBottom: "30px" }}>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 6 }}
            lg={{ span: 6 }}
            xl={{ span: 16, offset: 4 }}
          >
            <Row
              style={{
                marginTop: "24px",
                backgroundColor: "rgb(245, 245, 245)",
                lineHeight: "50px",
                fontWeight: "700",
                textAlign: "center",
                border: "1px solid rgb(221, 221, 221)"
              }}
            >
              <Col
                className="job-titl"
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 6 }}
                lg={{ span: 6 }}
                xl={{ span: 8 }}
              >
                职位名称
              </Col>
              <Col
                className="job-title"
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 6 }}
                lg={{ span: 6 }}
                xl={{ span: 4 }}
              >
                类别
              </Col>
              <Col
                className="job-title"
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 6 }}
                lg={{ span: 6 }}
                xl={{ span: 4 }}
              >
                工作地
              </Col>
              <Col
                className="job-title"
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 6 }}
                lg={{ span: 6 }}
                xl={{ span: 4 }}
              >
                发布时间
              </Col>
              <Col
                className="job-title"
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 6 }}
                lg={{ span: 6 }}
                xl={{ span: 4 }}
              >
                职位需求
              </Col>
            </Row>
            <Row
              className="ant-row"
              style={{
                minHeight: "50px",
                lineHeight: "50px",
                fontWeight: "700",
                textAlign: "center",
                borderWidth: "1px",
                borderStyle: "solid solid dashed",
                borderColor:
                  "rgb(221, 221, 221) rgb(221, 221, 221) rgb(235, 235, 235)"
              }}
            >
              <Col
                className="job-title"
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 6 }}
                lg={{ span: 6 }}
                xl={{ span: 8 }}
              >
                前端开发工程师
              </Col>
              <Col
                className="job-title"
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 6 }}
                lg={{ span: 6 }}
                xl={{ span: 4 }}
              >
                技术
              </Col>
              <Col
                className="job-title"
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 6 }}
                lg={{ span: 6 }}
                xl={{ span: 4 }}
              >
                北京
              </Col>
              <Col
                className="job-title"
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 6 }}
                lg={{ span: 6 }}
                xl={{ span: 4 }}
              >
                2018年11月29日
              </Col>
              <Col
                className="job-title"
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 6 }}
                lg={{ span: 6 }}
                xl={{ span: 4 }}
              >
                <img
                  className="show"
                  id="leftfront"
                  onClick={() => this.handleClick("leftfront")}
                  src={arrow}
                />
                <img
                  className="hiden"
                  id="bottomfront"
                  onClick={() => this.handleClick("bottomfront")}
                  src={arrow2}
                />
              </Col>
            </Row>
            <div id="front-describe" className="job-describe hiden">
              <div className="">
                <p className="job-describe-title">职位描述：</p>
                <div>
                  1、根据需求完成前端页面的构建，设计与实现；
                  <br />
                  2、优化web/移动前端应用，改善客户体验；
                  <br />
                  3、持续优化前端体验，完成UI交互，动画效果的实现
                </div>
                <p className="job-describe-title">任职资格：</p>
                <div>
                  1.能独立完成前端开发工作,对当下时尚潮流的设计元素有较高的敏感词和使用创意；
                  <br />
                  2.熟悉htmlcssJavaScript、Ajax等Web开发技术、HTML5/XHTML、CSS3等网页制作技术；
                  <br />
                  3.熟悉当前流行的JavaScript类库,熟悉JavaScript面向对象编程方法、熟悉jquery、easyUI、bootstrap等前端框架；
                  <br />
                  4.掌握VUE/JQuery/AngularJS/React等开发框架，熟悉主流移动浏览器的技术特点，熟练掌握移动端H5开发；
                </div>
              </div>
            </div>
            <div
              className="ant-row"
              style={{
                minHeight: "50px",
                lineHeight: "50px",
                fontWeight: "700",
                textAlign: "center",
                borderWidth: "1px",
                borderStyle: "solid solid dashed",
                borderColor:
                  "rgb(221, 221, 221) rgb(221, 221, 221) rgb(235, 235, 235)"
              }}
            >
              <Col
                className="job-titl"
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 6 }}
                lg={{ span: 6 }}
                xl={{ span: 8 }}
              >
                JAVA开发工程师
              </Col>
              <Col
                className="job-title"
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 6 }}
                lg={{ span: 6 }}
                xl={{ span: 4 }}
              >
                技术
              </Col>
              <Col
                className="job-title"
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 6 }}
                lg={{ span: 6 }}
                xl={{ span: 4 }}
              >
                北京
              </Col>
              <Col
                className="job-title"
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 6 }}
                lg={{ span: 6 }}
                xl={{ span: 4 }}
              >
                2018年11月29日
              </Col>
              <Col
                className="job-title"
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 6 }}
                lg={{ span: 6 }}
                xl={{ span: 4 }}
              >
                <img
                  className="show"
                  id="leftjava"
                  src={arrow}
                  onClick={() => this.handleClick("leftjava")}
                />
                <img
                  className="hiden"
                  id="bottomjava"
                  src={arrow2}
                  onClick={() => this.handleClick("bottomjava")}
                />
              </Col>
            </div>
            <Row id="java-describe" className="job-describe hiden">
              <div className="">
                <p className="job-describe-title">职位描述：</p>
                <div>
                  1、负责E运通平台的后端接口开发；
                  <br />
                  2、负责对接第三方系统的接口功能开发；
                  <br />
                  3、负责新项目后端接口功能开发。
                </div>
                <p className="job-describe-title">任职资格：</p>
                <div>
                  1、计算机或者相关专业，统招全日制大专及以上学历；
                  <br />
                  2、良好的问题理解能力，能够理解以及处理复杂逻辑；
                  <br />
                  3、具备较强的自学能力，团队精神及抗压能力；
                  <br />
                  4、2年以上的Java开发及应用经验，掌握JSDK，J2EE的相关知识，熟练JAVA、JSP、SERVERLET编程，熟悉多线程编程，熟练掌握AJAX，jquery；
                  <br />5 、熟练掌握SSM框架开发模式， 掌握Redis, memcache, Solr,
                  MQ一种或多种技术使用，
                  熟悉HttpClient、Dubbo、WebService任意一种RPC框架；
                  <br />6
                  、掌握数据库的相关知识，熟悉Mysql及SqlServer，具备SQL、触发器、函数、存储过程的编写能力；
                  <br />
                  7、有物流系统开发经验优先。
                  <br />
                </div>
              </div>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}
