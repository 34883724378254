import React from "react";
import { Row, Col } from "antd";
import TMSBanner from "../../../img/homeETMS/TMS-home-banner.png";
import banner1 from "../../../img/homeETMS/banner-01.png";
import banner2 from "../../../img/homeETMS/banner-02.png";
import banner3 from "../../../img/homeETMS/banner-03.png";
import linkBtn from "../../../img/homeETMS/link-btn.png";
import product1 from "../../../img/homeETMS/product-small-01.png";
import product2 from "../../../img/homeETMS/product-small-02.png";
import product3 from "../../../img/homeETMS/product-small-03.png";
import product4 from "../../../img/homeETMS/product-small-04.png";
import "./index.less";

export default class ETMS extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div style={{ width: "100%", height: "100%" }}>
        <div className="ETMS-box">
          <img src={TMSBanner} className="ETMS-banner-img" />
          <div className="ETMS-banner-right-box">
            <p className="ETMS-banner-h1">E-TMS系统</p>
            <p className="ETMS-banner-content">
              你想要的，我们都有，你想不到的， 我们也有，智慧物流，智慧生活。
            </p>
            <Row
              type="flex"
              justify="space-between"
              className="banner-button-box"
            >
              <Col span={6} className="banner-button-item">
                <img src={banner1} />
                <span className="bannner-img-describe">观看视频</span>
              </Col>
              <Col span={6} className="banner-button-item">
                <img src={banner2} />
                <span className="bannner-img-describe">登录系统</span>
              </Col>
              <Col span={6} className="banner-button-item">
                <img src={banner3} />
                <span className="bannner-img-describe">立即试用</span>
              </Col>
            </Row>
            <span
              className="banner-link-btn"
              onClick={() => {
                this.props.history.push({
                  pathname: "/product/TMS/Home"
                });
              }}
            >
              进入ETMS系统
              <img src={linkBtn} />
            </span>
          </div>
        </div>
        <div className="ETMS-box2">
          <p className="ETMS-box2-title">全流程订单分段管理、智能追踪</p>
          <div className="ETMS-product-box">
            <div className="ETMS-product-item-box">
              <p className="ETMS-product-item-title">
                智能追踪
                <span></span>
              </p>
              <img src={product1} />
            </div>
            <div className="ETMS-product-item-box">
              <p className="ETMS-product-item-title">
                智能结算
                <span></span>
              </p>
              <img src={product2} />
            </div>
            <div className="ETMS-product-item-box">
              <p className="ETMS-product-item-title">
                数据可视化
                <span></span>
              </p>
              <img src={product3} />
            </div>
            <div className="ETMS-product-item-box">
              <p className="ETMS-product-item-title">
                资源共享
                <span></span>
              </p>
              <img src={product4} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
