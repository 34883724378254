import React from "react";
import "./solution.less";
import topbg from "../../../img/solution/3pl-solution-banner.png";
class Forwarder extends React.Component {
  render() {
    return (
      <div className="title_bj">
        <div className="forwarder-top-box">
          <img
            className="solve-bj"
            src={topbg}
            alt="产品功能"
            style={{ marginLeft: 0 }}
          />
          <p className="forwarder-top-title">3PL解决方案</p>
          <span className="top-text">
            E-TMS系统+承运商端+司机端+E-TMS查询系统
          </span>
        </div>
      </div>
    );
  }
}

export default Forwarder;
