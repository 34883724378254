import React from "react";
import QueueAnim from "rc-queue-anim";
import TweenOne from "rc-tween-one";
import OverPack from "rc-scroll-anim/lib/ScrollOverPack";
import customerApp from "../../img/Home/customer.png";
class Content extends React.Component {
  static defaultProps = {
    className: "content1"
  };

  render() {
    const props = { ...this.props };
    const isMobile = props.isMobile;
    delete props.isMobile;
    const animType = {
      queue: isMobile ? "bottom" : "left",
      one: isMobile
        ? { y: "+=30", opacity: 0, type: "from" }
        : { x: "+=30", opacity: 0, type: "from" }
    };
    return (
      <div
        {...props}
        className={`content-template-wrapper content-half-wrapper ${
          props.className
        }-wrapper`}
      >
        <OverPack
          className={`content-template ${props.className}`}
          location={props.id}
        >
          <QueueAnim
            type={animType.queue}
            className={`${props.className}-text`}
            key="text"
            leaveReverse
            ease={["easeOutCubic", "easeInCubic"]}
            id={`${props.id}-textWrapper`}
          >
            <h1 key="h1" id={`${props.id}-title`}>
              客户端
            </h1>
            <p className="lead" style={{ color: "#404040" }}>
              直接沟通承运商，线上开单，高效协作
            </p>
            <p key="p" id={`${props.id}-content`}>
              及时掌握订单状态，一目了然，实时查看货运进程，监控异常，反馈更及时
            </p>
          </QueueAnim>
          <TweenOne
            key="img"
            animation={animType.one}
            className={`${props.className}-img`}
            id={`${props.id}-imgWrapper`}
            resetStyle
          >
            <span id={`${props.id}-img`}>
              <img
                style={{ height: "100%", width: "100%" }}
                src={customerApp}
              />
            </span>
          </TweenOne>
        </OverPack>
      </div>
    );
  }
}

export default Content;
