import React from "react";
import "./solution.less";
import topbg from "../../../img/solution/top.png";
class Customer extends React.Component {
  render() {
    return (
      <div className="title_bj">
        <div className="customer-top-box">
          <img
            className="solve-bj"
            src={topbg}
            alt="产品功能"
            style={{ marginLeft: 0 }}
          />
          <p className="customer-top-title">货主端解决方案</p>
          <span className="top-text">
            通过ETMS系统+手机客户端+ETMS查询系统 全面解决您的痛点、难点
          </span>
        </div>
      </div>
    );
  }
}

export default Customer;
