import React from "react";
import { Row, Col } from "antd";
import "./index.less";
export default class Join extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <div className="contraband-box">
        <div className="content-box">
            <p></p>
        </div> 
    </div>;
  }
}
