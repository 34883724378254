import React from "react";
import QueueAnim from "rc-queue-anim";
import TweenOne from "rc-tween-one";
import OverPack from "rc-scroll-anim/lib/ScrollOverPack";
import pic1 from "../../../img/solution/3pl1.png";
import pic2 from "../../../img/solution/3pl2.png";
import pic3 from "../../../img/solution/3pl3.png";
import pic4 from "../../../img/solution/3pl4.png";
import pic5 from "../../../img/solution/3pl5.png";
import pic6 from "../../../img/solution/3pl6.png";
class Content extends React.Component {
  static defaultProps = {
    className: "content2"
  };

  getDelay = e => (e % 3) * 100 + Math.floor(e / 3) * 100 + 300;

  render() {
    const props = { ...this.props };
    delete props.isMobile;
    const oneAnim = {
      y: "+=30",
      opacity: 0,
      type: "from",
      ease: "easeOutQuad"
    };
    const blockArray = [
      {
        icon: pic1,
        title: "运力直采",
        content:
          "建立三方物流企业专属的干线网上运力资源池，划分优质运力资源池，减少中间环节降低交易成本"
      },
      {
        icon: pic2,
        title: "管理清晰",
        content:
          "根据不同角色分配不同权限，组织管理员自主分配权限，实现各司其职，合作明确"
      },
      {
        icon: pic3,
        title: "降低成本",
        content:
          "传统物流行业，需求沟通成本过大，找司机，找干线，转移到线上可以在线上清晰分工，一键下单"
      },
      {
        icon: pic4,
        title: "提高效率",
        content:
          "司机可以直接在app或小程序上了解收发货地址，避免无效沟通；微信、app一键扫描进行交接"
      },
      {
        icon: pic5,
        title: "建立品牌形象",
        content:
          "不同企业配置不同logo，定制主题；传统流程转为线上，全面实现信息化"
      },
      {
        icon: pic6,
        title: "精准分析",
        content:
          "合作车辆数据，运单数据，资金数据，成本数据等，全方位分析展示，给管理者提供有效参考"
      }
    ];
    const children = blockArray.map((item, i) => {
      const id = `block${i}`;
      const delay = this.getDelay(i);
      const liAnim = { opacity: 0, type: "from", ease: "easeOutQuad", delay };
      const childrenAnim = { ...liAnim, x: "+=10", delay: delay + 100 };
      return (
        <TweenOne
          component="li"
          animation={liAnim}
          key={i}
          id={`${props.id}-${id}`}
        >
          <TweenOne
            animation={{
              x: "-=10",
              opacity: 0,
              type: "from",
              ease: "easeOutQuad"
            }}
            className="img"
            key="img"
          >
            <img src={item.icon} width="100%" />
          </TweenOne>
          <div className="text">
            <TweenOne key="h1" animation={childrenAnim} component="h1">
              {item.title}
            </TweenOne>
            <TweenOne
              key="p"
              animation={{ ...childrenAnim, delay: delay + 200 }}
              component="p"
            >
              {item.content}
            </TweenOne>
          </div>
        </TweenOne>
      );
    });
    return (
      <div
        {...props}
        className={`content-template-wrapper ${props.className}-wrapper bgF9`}
      >
        <OverPack className="Fsolution-corevalue-box" location={props.id}>
          <TweenOne
            key="h1"
            animation={oneAnim}
            component="h1"
            id={`${props.id}-title`}
            reverseDelay={100}
            className="corevalue-title"
          >
            核心价值
          </TweenOne>
          <TweenOne
            key="p"
            animation={{ ...oneAnim, delay: 100 }}
            component="p"
            id={`${props.id}-titleContent`}
            className="corevalue-title-describe"
          >
            优质，优越，优秀
          </TweenOne>
          <QueueAnim
            key="ul"
            type="bottom"
            className={`${props.className}-contentWrapper`}
            id={`${props.id}-contentWrapper`}
          >
            <ul key="ul">{children}</ul>
          </QueueAnim>
        </OverPack>
      </div>
    );
  }
}

export default Content;
