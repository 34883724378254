import React from "react";
import { enquireScreen } from "enquire-js";

import Customer from "./Customer";
import { BackTop, Row, Col } from "antd";

import solution1 from "../../../img/solution/solution1.png";
import solution2 from "../../../img/solution/solution2.png";
import solution3 from "../../../img/solution/solution3.png";
import solution4 from "../../../img/solution/solution4.png";
import solution5 from "../../../img/solution/solution5.png";
import solutionColumn1 from "../../../img/solution/customer-solution-1.png";
import solutionColumn2 from "../../../img/solution/customer-solution-2.png";
import solutionColumn3 from "../../../img/solution/customer-solution-3.png";

import "./less/antMotion_style.less";

let isMobile;
enquireScreen(b => {
  isMobile = b;
});

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile
    };
  }

  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen(b => {
      this.setState({ isMobile: !!b });
    });
  }

  render() {
    return (
      <div className="templates-wrapper">
        <Customer />
        <div className="solution-bg">
          <Row>
            <Col
              xs={{ span: 12 }}
              sm={{ span: 12 }}
              md={{ span: 6 }}
              lg={{ span: 3, offset: 3 }}
            >
              <img src={solution1} />
              <span>车辆实时监控</span>
            </Col>
            <Col
              xs={{ span: 12 }}
              sm={{ span: 12 }}
              md={{ span: 6 }}
              lg={{ span: 3, offset: 1 }}
            >
              <img src={solution2} />
              <span>运单智能配载</span>
            </Col>
            <Col
              xs={{ span: 12 }}
              sm={{ span: 12 }}
              md={{ span: 6 }}
              lg={{ span: 3, offset: 1 }}
            >
              <img src={solution3} />
              <span>司机车辆集中管理</span>
            </Col>
            <Col
              xs={{ span: 12 }}
              sm={{ span: 12 }}
              md={{ span: 6 }}
              lg={{ span: 3, offset: 1 }}
            >
              <img src={solution4} />
              <span>财务自动核算</span>
            </Col>
            <Col
              xs={{ span: 12 }}
              sm={{ span: 12 }}
              md={{ span: 6 }}
              lg={{ span: 3, offset: 1 }}
            >
              <img src={solution5} />
              <span>运输全程管理</span>
            </Col>
          </Row>
        </div>
        <div className="customer-solution-content bgF9">
          <Row className="customer-solution-item-box">
            <Col
              xs={{ span: 24 }}
              md={{ span: 10 }}
              className="height100 paddingLeft100"
            >
              <p className="customer-solution-item-title">
                智能追踪
                <span></span>
              </p>
              <p className="customer-solution-item-describe">
                通过“小黑卡”，GPS+基站+WIFI快速定位，超长待机，实现对货物跨城市、跨地区百分百精准定位，自动生成运输轨迹，在平台各个系统以及应用上随时查看，真正实时监控。
              </p>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 14 }} className="height100">
              <img src={solutionColumn1} className="positionRight" />
            </Col>
          </Row>
        </div>
        <div className="customer-solution-content">
          <Row className="customer-solution-item-box">
            <Col xs={{ span: 24 }} md={{ span: 14 }} className="height100">
              <img src={solutionColumn2} className="positionLeft" />
            </Col>
            <Col
              xs={{ span: 24 }}
              md={{ span: 10 }}
              className="height100 paddingRight100"
            >
              <p className="customer-solution-item-title">
                数据可视化分析
                <span></span>
              </p>
              <p className="customer-solution-item-describe">
                提供货主BI分析页面，微信扫码一键登录，完善的数据管理体系，各类报表多维度关联分析，订单状态、车辆数据、异常预警、企业运营数据等都可通过数据看板以动态图文的形式实时呈现，为企业决策提供有力依据
              </p>
            </Col>
          </Row>
        </div>
        <div className="customer-solution-content bgF9">
          <Row className="customer-solution-item-box">
            <Col
              xs={{ span: 24 }}
              md={{ span: 10 }}
              className="height100 paddingLeft100"
            >
              <p className="customer-solution-item-title">
                全面管理
                <span></span>
              </p>
              <p className="customer-solution-item-describe">
                系统对接货主、承运商两方，实现了双方信息的互通。通过ETMS系统，客户端，小程序以及查询系统可实时查看运单信息，监控货物位置，管理司机，管理承运商。根据角色不同可分配给员工不同权限，一套系统，多种管理层次
              </p>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 14 }} className="height100">
              <img
                src={solutionColumn3}
                className="positionRight solution-column3Positon"
              />
            </Col>
          </Row>
        </div>
        <div className="onTrial-button-box">
          <span className="onTrial-button">预约试用</span>
          <span className="onTrial-tell">400-698-5656</span>
        </div>
        <BackTop />
      </div>
    );
  }
}
