import React from "react";
import QueueAnim from "rc-queue-anim";
import TweenOne from "rc-tween-one";
import OverPack from "rc-scroll-anim/lib/ScrollOverPack";
import forward from "../../img/Home/forward.png";
class Content extends React.Component {
  static defaultProps = {
    className: "content0"
  };

  render() {
    const props = { ...this.props };
    const isMobile = props.isMobile;
    delete props.isMobile;
    const animType = {
      queue: isMobile ? "bottom" : "right",
      one: isMobile
        ? { y: "+=30", opacity: 0, type: "from" }
        : { x: "-=30", opacity: 0, type: "from" }
    };
    return (
      <div
        {...props}
        className={`content-template-wrapper content-half-wrapper ${
          props.className
        }-wrapper`}
      >
        <OverPack
          className={`content-template ${props.className}`}
          location={props.id}
        >
          <TweenOne
            key="img"
            animation={animType.one}
            className={`${props.className}-img`}
            id={`${props.id}-imgWrapper`}
            resetStyle
          >
            <span id={`${props.id}-img`}>
              <img style={{ height: "100%", width: "100%" }} src={forward} />
            </span>
          </TweenOne>
          <QueueAnim
            className={`${props.className}-text`}
            type={animType.queue}
            key="text"
            leaveReverse
            ease={["easeOutCubic", "easeInCubic"]}
            id={`${props.id}-textWrapper`}
          >
            <h1 key="h1" id={`${props.id}-title`}>
              承运商端
            </h1>
            <p className="lead" style={{ color: "#404040" }}>
              秒级开单，不错过客户订单，查单找货，助您管车管货管财务，开启高效协同模式
            </p>
            <p key="p" id={`${props.id}-content`}>
              收发货人智能填充/再下一单，方便快捷/实时查单，历史下单，统统掌握，运单状态，一目了然
            </p>
          </QueueAnim>
        </OverPack>
      </div>
    );
  }
}

export default Content;
