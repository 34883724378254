import React from "react";
import QueueAnim from "rc-queue-anim";
import TweenOne from "rc-tween-one";
import OverPack from "rc-scroll-anim/lib/ScrollOverPack";
import WeChat from "../../img/Home/wechat.png";

class Content extends React.Component {
  static defaultProps = {
    className: "content0"
  };

  render() {
    const props = { ...this.props };
    const isMobile = props.isMobile;
    delete props.isMobile;
    const animType = {
      queue: isMobile ? "bottom" : "right",
      one: isMobile
        ? { y: "+=30", opacity: 0, type: "from" }
        : { x: "-=30", opacity: 0, type: "from" }
    };
    return (
      <div
        {...props}
        className={`content-template-wrapper content-half-wrapper ${
          props.className
        }-wrapper`}
      >
        <OverPack
          className={`content-template ${props.className}`}
          location={props.id}
        >
          <TweenOne
            key="img"
            animation={animType.one}
            className={`${props.className}-img`}
            id={`${props.id}-imgWrapper`}
            resetStyle
          >
            <span id={`${props.id}-img`}>
              <img width="100%" src={WeChat} />
            </span>
          </TweenOne>
          <QueueAnim
            className={`${props.className}-text`}
            type={animType.queue}
            key="text"
            leaveReverse
            ease={["easeOutCubic", "easeInCubic"]}
            id={`${props.id}-textWrapper`}
          >
            <h1 key="h1" id={`${props.id}-title`}>
              小程序
            </h1>
            <p className="lead" style={{ color: "#404040" }}>
              更方便，更简易，利于推广，主要功能全部包含
            </p>
            <p key="p" id={`${props.id}-content`}>
              结合三端App，多种功能，一套掌控。省下下载过程，简单易用
            </p>
          </QueueAnim>
        </OverPack>
      </div>
    );
  }
}

export default Content;
