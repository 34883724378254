import React from "react";
import { Row, Col } from "antd";
import videoButton from "../../../img/homeETMS/show-video-button.png";
import homeProduct01 from "../../../img/homeETMS/home-product-1.png";
import homeProduct02 from "../../../img/homeETMS/home-product-2.png";
import homeProduct03 from "../../../img/homeETMS/home-product-3.png";
import homeProduct04 from "../../../img/homeETMS/home-product-4.png";
import "./index.less";

export default class Home extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div style={{ width: "100%", height: "100%" }}>
        <div className="home-video-box">
          <p className="home-vidoe-h1">E-TMS系统</p>
          <p className="home-vidoe-h2">智慧物流 智慧生活</p>
          <p>
            <img
              src={videoButton}
              alt="观看视频"
              className="home-video-button"
            />
            <span className="home-video-describe">观看视频介绍</span>
          </p>
        </div>
        <div className="home-main-container">
          <Row className="home-product-describe-box">
            <Col xs={{ span: 24 }} md={{ span: 14 }}>
              <img src={homeProduct01} className="home-product-img" />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 10 }}>
              <p className="home-product-title">
                智能追踪
                <span></span>
              </p>
              <p className="home-product-describe">
                以NB-IoT窄带物联网技术，百分百精准定位追踪货物与车辆，实时上报位置、承运商、异常等信息，解决长途运输层层分包信息断层问题，真正实现“一单到底”。
              </p>
            </Col>
          </Row>
        </div>
        <div className="home-main-container bgFA">
          <Row className="home-product-describe-box">
            <Col xs={{ span: 24 }} md={{ span: 10 }}>
              <p className="home-product-title">
                智能结算
                <span></span>
              </p>
              <p className="home-product-describe">
                上下游承运商运费层层分段管理，批量自动结算，大幅减轻财务团队压力，提升效率，节省人工成本。
              </p>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 14 }}>
              <img src={homeProduct02} className="home-product-img fRight" />
            </Col>
          </Row>
        </div>
        <div className="home-main-container">
          <Row className="home-product-describe-box">
            <Col xs={{ span: 24 }} md={{ span: 14 }}>
              <img src={homeProduct03} className="home-product-img" />
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 10 }}>
              <p className="home-product-title">
                数据可视化
                <span></span>
              </p>
              <p className="home-product-describe">
                完善的数据管理体系，各类报表多维度关联分析，并通过数据看板以可视化动态图文的形式实时呈现，为企业决策提供有力依据。
              </p>
            </Col>
          </Row>
        </div>
        <div className="home-main-container bgFA">
          <Row className="home-product-describe-box">
            <Col xs={{ span: 24 }} md={{ span: 10 }}>
              <p className="home-product-title">
                资源共享
                <span></span>
              </p>
              <p className="home-product-describe">
                基于数字化管理，实现平台系统、仓位、办公、设施、车辆、人才等资源的共享，提高流通效率、降低物流成本，带动商贸物流业转型升级，实现传统物流行业新旧动能转换。
              </p>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 14 }}>
              <img src={homeProduct04} className="home-product-img fRight" />
            </Col>
          </Row>
        </div>
        <div className="onTrial-button-box">
          <span className="onTrial-button">预约试用</span>
          <span className="onTrial-tell">400-698-5656</span>
        </div>
      </div>
    );
  }
}
